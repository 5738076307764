<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_form_paquete_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar listado Paquetes
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="nombre" class="col-md-5">Nombre</label>
                    <input
                      type="text"
                      id="nombre"
                      v-model="filtros.nombre"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="linea_negocio_id" class="col-md-5"
                      >Linea de Negocio</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.linea_negocio_id"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="linea_negocio_id in listasForms.lineas_negocio"
                        :key="linea_negocio_id.id"
                        :value="linea_negocio_id.id"
                      >
                        {{ linea_negocio_id.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="entidad" class="col-md-5"
                      >Entidad</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.entidad"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="entidad in listasForms.entidades"
                        :key="entidad.numeracion"
                        :value="entidad.numeracion"
                      >
                        {{ entidad.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="nombre1"
                              v-model="form.nombre"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="nombre1"
                              >Nombre</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="linea_negocio_id1"
                              v-model="form.linea_negocio_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="linea_negocio_id1"
                              >Linea de Negocio</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="entidad1"
                              v-model="form.entidad"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="entidad1"
                              >Entidad</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('admin.paquetes.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "PaqueteDocumentoExport", //llegada tambien
  components: { Loading },
  data() {
    return {
      cargando:false,
      form: {
        nombre: true,
        linea_negocio_id: true,
        entidad: true,
      },
      filtros: {
        nombre: null,
        linea_negocio_id: null,
        entidad: null,
      },

      listasForms: {
        lineas_negocio: [],
        entidades: [],
      },
    };
  },

  methods: {
    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    getEntidades() {
      axios.get("/api/lista/5").then((response) => {
        this.listasForms.entidades = response.data;
      });
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/paquetes/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    this.getLineasNegocio();
    this.getEntidades();
    this.cargando = false;
  },
};
</script>
